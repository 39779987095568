import { graphql } from "gatsby";
import React from "react";
import { SEO, Layout } from "../components";

const IndexPage = ({ data }) => {
  const landingPage = data.sanityLandingpage;
  const seo = data.sanitySeo;
  return (
    <div>
      <SEO
        title={seo.title}
        description={seo.description}
        keywords={seo.keywords}
        ldSchema={seo.ldSchema}
      />
      <Layout data={landingPage} />
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  {
    sanityLandingpage {
      herosection {
        title
        price
        propertyStatus
        propertyLink
        soldDate(formatString: "YYYY")
        image {
          asset {
            gatsbyImageData(
              layout: FULL_WIDTH
              formats: WEBP
              placeholder: DOMINANT_COLOR
              fit: MAX
            )
          }
        }
      }
      activeProperties {
        title
        subtitle
        properties {
          propertyname
          slug {
            current
          }
          propertyprice
          propertystatus
          propertyimage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                placeholder: DOMINANT_COLOR
                fit: MAX
                width: 450
              )
            }
          }
        }
        buttontext
        buttonlink
      }
      bussinessstats {
        title
        stats
      }
      soldproperties {
        title
        properties {
          propertyname
          propertylocation
          slug {
            current
          }
          propertyprice
          propertystatus
          propertyimage {
            asset {
              gatsbyImageData(
                layout: FULL_WIDTH
                formats: WEBP
                placeholder: DOMINANT_COLOR
                fit: MAX
                width: 420
              )
            }
          }
          propertyarea
          propertybedrooms
          propertybathrooms
        }
      }
      videosection {
        title
        backgroundImage {
          asset {
            gatsbyImageData(
              layout: FIXED
              formats: WEBP
              placeholder: DOMINANT_COLOR
              fit: MAX
            )
          }
        }
        video
      }
      mendocinoestate {
        title
        description
        image {
          asset {
            gatsbyImageData(
              layout: FIXED
              formats: WEBP
              placeholder: DOMINANT_COLOR
              fit: MAX
              width: 380
            )
          }
        }
      }
    }
    sanitySeo(pagehandle: { eq: "home" }) {
      title
      description
      keywords
      ldSchema
    }
  }
`;
